@import 'src/App';

.custom-card-container {
  .custom-card-img {
    margin-top: 1rem !important;

    max-height: 100px;
    max-width: 200px;
  }

  .custom-card {
    border-radius: 10px;
    background-color: @light-green;
    height: 331px;

    .ant-card-body {
      padding: 10px;
      height: 100%;
    }

    .custom-card-container {
      height: 100%;
    }
  }

  .custom-card-small-text {
    line-height: 25px !important;
    font-size: 15px !important;
  }

  .custom-card-title {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;

    font-size: 18px;
    line-height: 2rem;
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;