@import 'node_modules/antd/es/style/themes/default';
// Variables Ant
@import 'src/App';

.discover-filter-container {
  .filter-bar-icons {
    width: 16px;
    top: -2px;
    position: relative;
  }

  .filter-button {
    border-color: white !important;
    color: black !important;
    flex: 0 0 auto;
    max-width: fit-content;
    height: 38px !important;

    &.hero-categories-dropdown,
    &.product-categories-dropdown {
      border-color: @light-green !important;
      background-color: @light-green;
    }
  }

  .scrollable-filters-scroll {
    overflow-x: scroll !important;
  }

  .scrollable-filters {
    margin: 0 -16px;

    .filters-collection {
      display: flex;
      flex-direction: row;
    }

    .filters-collection:first-child {
      padding-left: 16px;
    }

    .filters-collection:last-child {
      padding-right: 16px;
    }

    .filters-collection:first-child > * {
      margin-right: 16px;
    }
  }

  .ant-dropdown-menu {
    max-height: 50vh !important;
    overflow-y: auto !important;
  }

  .dropdown-products-text {
    float: left;
  }

  .dropdown-products-icon,
  .dropdown-products-icon-delete {
    float: right;
    right: -10px;
  }

  .dropdown-products-icon {
    top: 6px !important;
  }

  .dropdown-products-icon-delete {
    top: 2px !important;
  }

  @media (min-width: @screen-md-min) {
    .filter-button {
      &.product-categories-dropdown {
        width: 436px;
        max-width: 436px;
      }
    }
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;