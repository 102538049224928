@import '~antd/es/style/themes/default'; // Variables Ant
@import '../../App';

.ant-btn-lg {
  border-radius: @btn-border-radius-lg;
}

.btn-block-until-md {
  width: 100%;
}
@media (min-width: @screen-md) {
  .btn-block-until-md {
    width: 320px;
  }
}
