@import '../../App';
@import '~antd/es/style/themes/default'; // Variables Ant

h1,
h2,
.h1,
.h2 {
  font-family: 'Playfair Display', sans-serif;
}

h1,
.h1 {
  font-size: @heading-1-size;
  line-height: 37px;
}

h2,
.h2 {
  font-size: @heading-2-size;
  line-height: 24px;
}

h3,
.h3 {
  line-height: 20px;
  font-weight: 600;
}

h3.mobile-text,
p.mobile-text {
  font-size: 12px;
  line-height: 16px;
}

h3,
.h3,
p {
  font-family: 'Open Sans', sans-serif;
  font-size: @heading-3-size;
}

strong {
  font-weight: 600;
}

a {
  font-weight: 400;
  color: @primary-color;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.green {
  color: @primary-color !important;
}

.light-green {
  color: @light-green;
}

.red {
  color: @red-5;
}

.gray {
  color: @gray;
}

.black {
  color: black;
}

@media (min-width: @screen-md) {
  .text-md-center {
    text-align: center !important;
  }
}

.price-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.small-text {
  font-size: 13px;
  font-weight: 600;
}

.underlined {
  text-decoration: underline;
}
