@import '~antd/dist/antd.less';
@import './less/utilities.less';

//Colors
@light-green: #d9f8eb;
@light-gray: #f6f8f9;
@gray: #c1c1c1;
@dark-gray: #505050;

//Custom ant-override
@btn-border-radius-lg: 8px;
@shadow-default: 0 0 15px 0 rgba(0, 0, 0, 0.07);

//Custom sizes.
@app-header-height: 72px;

.local-heroes-main-layout {
  min-height: 100vh !important;

  .local-heroes-main-footer {
    padding: 0 !important;
    margin: 0 !important;
  }
}

img {
  max-width: 100%;
}

.loader {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100vw;
  height: 100vh;
}

//Override the default 8px border-radius-base set in Craco for all other elements.
// We want our checkboxes square.
.ant-checkbox-inner {
  border-radius: 0;
}

.ant-form-item-label {
  padding: 0 0 5px !important;
}

.small-width-container {
  max-width: 720px;
  position: relative;
  margin-bottom: 30px;
}

@media (max-width: @screen-md) {
  .small-width-container {
    padding: 0 20px;
  }

  .hide-small-screen {
    display: none;
  }
}

@media (min-width: @screen-md) {
  .small-width-container {
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .hide-large-screen {
    display: none;
  }
}

.white-bordered-background {
  background-color: white;
  border-radius: 20px;
  margin-bottom: 23px;
}

.grey-bordered-background {
  background-color: @light-gray;
  border-radius: 10px;
}

.icons {
  width: 16px;
  margin-right: 10px;
}

.icons-right {
  width: 16px;
  margin-left: 10px;
}

.icons-mobile {
  width: 12px;
  margin-right: 6px;
}

.icons,
.icons-right,
.icons-mobile {
  top: -2px;
  position: relative;
  display: inline !important;
}

.button-icon {
  width: 12px;
  margin-right: 10px;
  top: -2px;
  position: relative;
}

// For carousel left and right arrow
.discover-list-item .ant-carousel .slick-next,
.discover-list-item .ant-carousel .slick-next:hover,
.discover-list-item .ant-carousel .slick-prev,
.discover-list-item .ant-carousel .slick-prev:hover {
  z-index: 1;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 15px;
  color: white;
}

.min-height {
  min-height: calc(100vh - 144px - 4rem);
}

.search-bar,
.search-bar-mobile {
  .ant-select-selector {
    box-shadow: @shadow-default !important;
    border-color: @primary-color !important;
  }
}

.search-bar {
  width: 370px !important;
}

.ant-page-header-heading-title,
.ant-page-header-back-button {
  font-size: @font-size-base;
}

.ant-page-header-heading-title {
  display: none;
}

.helper-text {
  font-size: 13px;
  color: @gray;
}

.page-header-back {
  transition: 0.3s;

  .arrow-back {
    margin-right: 12px;
  }
}

.page-header-back:hover {
  color: @primary-color;
}

.list-header {
  width: 100%;
  height: 72px;
}

@media (max-width: @screen-sm-max) {
  .list-header {
    height: 130px !important;
  }
}

.ant-checkbox + span {
  cursor: default;
}
