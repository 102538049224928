@import '~antd/es/style/themes/default'; // Variables Ant

.d-none {
  display: none;
}

@media (min-width: @screen-md) {
  .d-md-block {
    display: block;
  }
}
