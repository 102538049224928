//@import 'node_modules/antd/es/style/themes/default';
//@import 'src/App';

.logout-button {
  span {
    //color: @red-base;
  }
}

.nav__menu-user {
  display: flex;
  height: 100%;
  align-items: center;

  &.ant-menu-horizontal {
    border: none;
  }

  &.ant-menu {
    background: transparent !important;
  }

  .nav__menu-dropdown {
    border: 1px solid #dadce2;

    img {
      width: 16px;
      height: 16px;
    }

    .user-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;