@import '~antd/dist/antd.less';
@import '../../App';

.footer-container {
  background: @light-green;
  padding: 2rem 0;

  .footer-social-media {
    img {
      margin-top: -3px;
      margin-right: 4px;
    }
  }

  .footer-text {
    color: @dark-gray;
    font-weight: normal !important;
  }

  .footer-language-button {
    width: 170px;
    border-color: @gray !important;
    color: @dark-gray;
  }

  .footer-copyright-xs {
    justify-content: end;
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;