@import '~antd/es/style/themes/default'; // Variables Ant

.login-drawer {
  z-index: 1500;

  .ant-drawer-mask {
    background-color: rgba(225, 225, 225, 0.5);
  }

  .ant-drawer-content-wrapper {
    .floating-login-drawer-back {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      --antd-wave-shadow-color: @primary-color;
      touch-action: manipulation;
      margin: 0;
      overflow: visible;
      box-sizing: border-box;
      position: absolute;
      z-index: 10;
      display: block;
      color: rgba(0, 0, 0, 0.45);
      line-height: 1;
      text-align: center;
      text-transform: none;
      text-decoration: none;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color 0.3s;
      text-rendering: auto;
      top: 1rem;
      left: 0.5rem;
      padding: 15px;

      &:hover {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: none;
      }
    }

    .login-drawer-header {
    }

    .ant-drawer-header-no-title {
      button.ant-drawer-close {
        top: 1rem;
        right: 0.5rem;
        padding: 15px;
        margin-right: 0;
      }
    }
  }

  .terms-link,
  .privacy-link {
    white-space: nowrap;
  }
}

@media not all and (min-width: 576px) {
  .login-drawer {
    .ant-drawer-content-wrapper {
      width: 100% !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@media (min-width: 576px) {
  .login-drawer {
    .ant-drawer-content-wrapper {
      width: 500px !important;
    }
  }
}

.sign-in-button {
  width: 100%;
  text-align: left;
  border-width: thin;
  border-style: solid;

  &.sign-in-google {
    border-color: gray;
    background-color: white;
    color: black;

    &:hover {
      border-color: @primary-color;
    }
  }

  &.sign-in-facebook {
    background-color: #3b5998;
    color: white;
  }

  &.sign-in-twitter {
    background-color: #55acee;
    color: white;
  }

  &.sign-in-github {
    background-color: black;
    color: white;
  }

  &.sign-in-phone {
    background-color: @primary-color;
    color: white;
  }

  &.sign-in-password {
    background-color: #db4437;
    color: white;
    border-color: #db4437;
  }

  .sign-in-button-icon {
    height: 1em;
    width: 1em;
    vertical-align: initial;
  }
}

.form-login-email,
.form-login,
.form-login-phone,
.form-reset-password {
  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    font-style: italic;
    min-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .input-email-next,
  .input-email-save,
  .input-recover-send,
  .input-create-account-save,
  .input-phone-verify,
  .input-phone-continue {
    margin-left: 10px;
  }

  .terms-privacy-footer,
  .disclaimer-phone-footer {
    .privacy-small {
      margin-left: 15px;
    }
  }
}

.error-message-login {
  color: #ff4d4f;
}

.login-body {
  min-height: calc(100vh - 144px - 8rem);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.button-connection,
.button-connection-map {
  color: @black;
  margin-right: -20px;
}

.button-connection-map {
  position: absolute;
  z-index: 1;
  right: 24px;
  top: 20px;
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;