@import 'node_modules/antd/es/style/themes/default';
// Variables Ant
@import 'src/App';

.segmented-control {
  position: fixed;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  z-index: 1000;
}

.place-card {
  cursor: pointer;
  border: 1px solid white !important;

  div.ant-card.ant-card-bordered {
    border-radius: 10px;
    overflow: hidden;
  }

  div.ant-card-body {
    padding: 13px 16px;
    height: 104px;
    font-size: 14px;
  }
}

.info-window-photo {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.discover-list-item {
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    color: white;
    z-index: 1;
    width: 30px;
    height: 30px;

    svg {
      transform: translateY(50%);
    }
  }

  .ant-carousel .slick-prev {
    left: 10px;
  }

  .ant-carousel .slick-next {
    right: 10px;
  }
}

.postal-code-city-row {
  .postal-code-button {
    line-height: 1;
    height: 42px;
    width: 56px;
    border: 1px solid @light-gray;
    color: @text-color;
    border-radius: @border-radius-sm 0 0 @border-radius-sm;
    padding: 6px 10px 7px 10px;

    &:hover,
    &:active {
      background-color: #eefdf6;
    }
  }

  .select-city {
    min-width: 130px;

    .ant-select-selector {
      border-radius: 0 @border-radius-sm @border-radius-sm 0;
      position: relative;
      left: -1px;
    }
  }

  .city-zip-autocomplete {
    min-width: 166px;

    .ant-select-selector {
      border-radius: 0;
      border-color: #53e6a1;
    }

    input.ant-select-selection-search-input {
    }
  }

  .postal-code-choose-button {
    line-height: 1;
    height: 42px;
    width: 56px;
    border-width: 1px;
    border-style: solid;
    border-radius: 0 @border-radius-sm @border-radius-sm 0;
    padding: 6.4px 7px;
  }
}

.add-hero-card {
  border-radius: 10px;
  background-color: @light-green;
  height: 331px;
  padding: 10px;

  .add-hero-card-container {
    height: 100%;
  }
}

.card-visual-hero {
  height: 80px;
}

.twint-filter {
  width: 60px;
  object-fit: contain;
}

.promotion-filter {
  margin-left: 0.1em;
  width: 20px;
  object-fit: contain;
}

.more-filters-button {
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 40px;

  &.closed {
    border: 1px solid #dadce2;
  }

  img {
    margin-left: -4px;
    min-width: 16px;
  }
}

.mode-group {
  .ant-radio-button-wrapper {
    height: 32px !important;
    padding: 0 7px !important;
    line-height: 28px !important;

    .mode-button {
      width: 21px !important;
    }
  }

  &&.map-mode {
    position: fixed;
    top: 86px;
    z-index: 1005;
  }
}

@media (min-width: @screen-md) {
  .mode-group {
    .ant-radio-button-wrapper {
      height: 40px;
      padding: 0 15px;
      line-height: 38px;

      .mode-button {
        width: 23px;
      }
    }
  }
}

.filters-large-screens,
.category-large-screens {
  display: none;
}

@media (min-width: @screen-md) {
  .filters-large-screens,
  .category-large-screens {
    display: block;

    .ant-select-arrow {
      //margin-right: 7px;
    }
  }
}

.filters-small-screens {
  .divider-filters {
    border-top-color: #e0e0e0;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .all-filters-button {
    width: 100%;
    color: #2ddd90;
    background-color: white;
    border-color: #2ddd90;
    border-width: thin;
    border-style: solid;
  }

  .close-all-filters-button {
    color: #2ddd90;
    background-color: white;
    border-color: #2ddd90;
    border-width: thin;
    border-style: solid;
  }

  .select-category-small-screen {
    width: 100%;
  }
}

@media (min-width: @screen-md) {
  .filters-small-screens {
    display: none;
  }
}

.add-hero-card-title {
  font-size: 1rem;
  line-height: 2rem;
}

.checkbox-deliver {
  line-height: 28px;
}

.filters-small-screens-hidden-group {
  background-color: white;
  border: 1px solid @light-gray;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 0 !important;
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;