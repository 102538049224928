@import 'node_modules/antd/es/style/themes/default';
// Variables Ant
@import 'src/App';
@import 'src/less/utilities/text';

.hero-card {
  width: 100%;

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    color: white;
    z-index: 1;
    width: 30px;
    height: 30px;

    svg {
      transform: translateY(50%);
    }
  }

  .ant-carousel .slick-prev {
    left: 10px;
  }

  .ant-carousel .slick-next {
    right: 10px;
  }
}

.modify-button,
.communicate-button,
.claim-button,
.claimed-button,
.delete-button {
  border-width: thin;
  border-style: solid;
}

.delete-button {
  color: @primary-color;
  border-color: @primary-color;

  &:hover {
    background-color: @light-green;
  }
}

.place-photo-card {
  max-width: 100%;
  height: 225px;
  object-fit: cover;
}

.place-photo {
  height: 50vw;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.row-place-name {
  flex-flow: row;
}

.card-icon {
  height: 15px;
}

.hero-subscription-col {
  margin: -4px 0 -4px 2px;
  height: 21px;
  width: auto;
}

.card-shop-icon-with-name {
  white-space: nowrap;
  overflow: hidden;
}

.card-shop-name {
  margin-left: 0;
}

.card-category {
  p {
    margin-bottom: 5px;
  }
}

.card-city-distance-delivery {
  color: @gray;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.card-city-distance {
  display: inline-block;
}

.card-delivery {
  float: right;
}

.promotion-logo-card {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
  width: 110px !important;
  object-fit: contain;

  &.fit-second-promotion {
    right: 120px;
  }
}

.ant-carousel {
  .hero-carousel {
    .slick-dots li button {
      background: white;
    }

    .slick-dots li.slick-active button {
      background: white !important;
    }

    .slick-prev,
    .slick-next {
      color: white !important;
    }

    .slick-prev {
      margin-left: 0 !important;
    }

    .slick-next {
      margin-right: 0 !important;
    }

    .slick-dots {
      margin-bottom: 0 !important;
    }
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;