@import './utilities/background';
@import './utilities/spacing';
@import './utilities/text';
@import './utilities/button';
@import './utilities/visibility';

@media (min-width: @screen-md) {
  @iterations: 5;
  .w-loop (@i) when (@i < 100) {
    .w-@{i} {
      width: ~'@{i}%';
      margin-left: auto;
      margin-right: auto;
    }
    .w-loop(@i + 5);
  }
  .w-loop (@iterations);
}

@media (min-width: @screen-sm) {
  .container {
    width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: @screen-md) {
  .container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: @screen-lg) {
  .container {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

.container {
  padding: 20px;
}

.container-horizontal {
  padding-left: 20px;
  padding-right: 20px;
}

.menu-header {
  border-bottom: 0;

  .ant-menu-item-selected {
    border-bottom: 3px solid #2bda8e !important;
  }
}
