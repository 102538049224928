@import 'node_modules/antd/es/style/themes/default';

.hero-map {
  @map-header-height: 110px;

  .desktop-header-row {
    @menu-button-height: @btn-square-size;
    @logo-container-height: 60px;
    @logo-container-border-radius: calc(@logo-container-height / 2);
    @header-padding-left: calc((@map-header-height - @logo-container-height) / 2);
    @header-padding-right: calc((@map-header-height - @menu-button-height) / 2);

    position: fixed;
    height: @map-header-height;
    padding-left: @header-padding-left;
    padding-right: @header-padding-right;
    //Disable the row overriding click and drag.
    pointer-events: none;

    .logo-button,
    .logo-button:hover {
      height: @logo-container-height;
      border-radius: @logo-container-border-radius;

      .logo-renderer {
        margin: 0 10px;
      }
    }
  }

  .mobile-header-row {
    @header-height: 72px;
    @header-margin-side: calc((@header-height - @btn-square-size) / 2);

    background-color: @white;
    height: @header-height;
    padding: 0 @header-margin-side 0 0;

    .logo-button,
    .logo-button:hover {
      border-radius: 0;
      height: @header-height;
    }
  }

  .header-row {
    width: 100%;
    z-index: 500;
  }

  .logo-button,
  .logo-button:hover {
    border: 0;
  }

  .logo-renderer {
    height: 20px !important;
  }

  .hero-list-button-container {
    width: 100%;
    z-index: 500;
    height: @map-header-height;
    position: absolute;
    //Disable the row overriding click and drag.
    pointer-events: none;
  }

  //Put the pointer event back on elements which need to receive them.
  .get-pointer-events {
    pointer-events: all;
  }

  ///Override user menu component.
  .user-menu {
    z-index: 500;
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;