@import 'node_modules/antd/dist/antd.less';

.newsletter-card {
  .enter-email {
    label {
      height: auto;

      &.ant-form-item-required::before {
        display: none;
      }
    }

    .ant-form-item-explain {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding-bottom: 0;
    }

    input {
      border-radius: @border-radius-sm;
    }
  }

  .enter-name {
    input {
      border-radius: @border-radius-sm;
    }

    label.ant-form-item-required::before {
      display: none;
    }

    .ant-form-item-explain {
      display: none;
    }
  }

  .checkbox-privacy label {
    .ant-checkbox-inner {
      border-radius: @border-radius-sm;
      width: 14px;
      height: 14px;

      &::after {
        top: 45%;
        left: 20%;
      }
    }
  }

  .button-register {
    button {
      width: 100%;
      height: auto;
      padding: 2px 0;
      border-radius: @border-radius-sm;

      &.ant-btn-primary {
        border: none;

        &[disabled] {
          color: #ffffff;
          background-color: #cccccc;
        }
      }
    }
  }
}

@primary-color: #2BDA8E;@text-color: #414141;@white: #FFFFFF;@red-base: #F5474C;@lightgray: #DADCE2;@body-background: #F6F8F9;@font-size-base: 15px;@font-size-sm: 13px;@font-family: Open Sans, Arial, sans-serif;@heading-1-size: 28px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: @text-color;@border-radius-base: 8px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-height-lg: 42px;@btn-font-size-lg: 15px;@btn-height-base: 32px;@btn-border-radius-base: 20px;@btn-default-color: #2BDA8E;@btn-default-border: #2BDA8E;@input-height-base: 42px;@layout-body-background: #F6F8F9;@layout-header-background: #FFFFFF;@layout-header-padding: 0;@form-item-margin-bottom: 18px;